.rheostat-horizontal {
  margin: 0 14px;
  width: auto;
  height: auto;
}

.rheostat-progress {
  background-color: #008489;
}

.rheostat-horizontal .rheostat-progress {
  height: 6px;
}

.rheostat-horizontal .rheostat-background {
  top: 2px;
  height: 6px;
  border: none;
  background-color: #d8d8d8 !important;
}

.rheostat-horizontal .rheostat-handle {
  margin-left: -16px;
  top: -12px;
}

.rheostat-handle {
  width: 0;
  padding: 16px;
  border: 1px solid #008489;
  border-radius: 50%;
  background: #fff;
}

.rheostat-handle:before,
.rheostat-handle:after {
  content: none;
}

button.DefaultHandle_handle {
  border-radius: 50%;
  border: 1px solid #6ac82c;
  top: -9px;
}

button.DefaultHandle_handle:after {
  background-color: #fff;
}

button.DefaultHandle_handle:before {
  background-color: #fff;
}

div.DefaultProgressBar_progressBar_1 {
  background-color: #6ac82c;
  position: absolute
}

div.DefaultProgressBar_background__horizontal {
  height: 5px;
  top: -2px;
}

div.DefaultBackground_background__horizontal {
  height: 5px;
  /*top: 3px;*/
  background-color: #d8d8d8;
  border-radius: 5px;
}
