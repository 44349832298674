body {
    margin: 0;
    font-family: 'Open Sans', 'Roboto', sans-serif;
}
#map {
    width:100%; height:100%;
}
.leftcol {
    -ms-flex: 0 0 840px;
    flex: 0 0 840px;
    display: block !important;
}
.rightcol {
    display: block;
    background: #ccc;
}

@media (max-width: 1128px) {
    .leftcol {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: 1129px) {
    .rightcol {
        left: auto !important;
        width: calc(100% - 840px) !important;
    }
}

@media (max-width: 1128px) {
  .rightcol {
    display: none !important;
  }
}

.row2 {
    min-height: calc(100vh - 80px);
    position: relative !important;
}
.row1 {
    height: 80px !important;
    left: 0px !important;
    width: 100% !important;
    z-index: 100 !important;
    background: yellow;
    display: block !important;
}
.navbar {
  height: 80px !important;
}
a.navbar-brand.full {
  display: block;
}
a.navbar-brand.mobile {
  display: none;
}
@media (max-width: 1128px) {
   a.navbar-brand.full {
     display: none !important;
   }
   a.navbar-brand.mobile {
     display: block !important;
   }
}
.datepicker {
    font-size: 0.875em;
}
.homePriceLabel {
    font-weight: bold;
    border-radius:12px;
    padding:5px 8px;
    font-weight: bold;
    font-size: 110%;
    font-family: 'Roboto', sans-serif;
    /*transition: all 0.1s ease-in-out;*/
}
.homePriceLabel.locate, .homePriceLabel:hover {
    z-index: 1000;
    background: #000;
    color: #fff;
    transform: scale(1.5);
}

button.searchChip {
  border: 1px solid #bbb;
  border-radius: 20px;
  padding: 4px 15px;
}
button.searchChip:hover {
  border: 1px solid #666;
}
button.searchChip.active {
  border: 2px solid #666;
  background: #f7f7f7;
}

.Modal {
    top                   : 50%;
    left                  : 50%;
    right                 : auto;
    bottom                : auto;
    marginRight           : -50%;
    transform             : translate(-50%, -50%);
  border-radius: 10px;
  background-color: #fff;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 10000;
}